import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AlertController,
  MenuController,
  ModalController,
  NavController,
} from '@ionic/angular';
import { AlertService } from 'src/app/_services/alert.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ModalAlertComponent } from '../modal-alert/modal-alert.component';
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  versionName: any;
  constructor(
    private nav: NavController,
    public auth: AuthService,
    public alertService: AlertService,
    public alertCtrl: AlertController,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    if (Capacitor.isNativePlatform()) {
      CapacitorApp.getInfo().then(info => {
        this.versionName = info.version;
      });
    }else{
      this.versionName = 'v3';
    }
  }

  goTo(page) {
    this.nav.navigateForward(page);
  }

  async presentAlertConfirm() {
    const alert = await this.alertCtrl.create({
      header: 'Perhatian!',
      message: 'Apakah anda yakin ingin keluar ?',
      cssClass: 'custom-alert-report',
      buttons: [
        {
          text: 'Batal',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          },
        },
        {
          text: 'Ya',
          handler: () => {
            this.logout();
          },
        },
      ],
    });

    await alert.present();
  }

  logout() {
    this.auth.logout();
    this.alertService.presentToast("You're logged out");
  }

  // async confirmLogout() {
  //   const modal = await this.modalCtrl.create({
  //     initialBreakpoint: 0.4,
  //     breakpoints: [0, 0.4],
  //     component: ModalAlertComponent,
  //     componentProps: {
  //       title: 'Apakah anda yakin ingin keluar ?',
  //       btnTitleConfirm: 'Logout',
  //       imageAlert: '../../../assets/images/ilustration/undraw_exit.svg',
  //     },
  //   });

  //   modal.onDidDismiss().then((data) => {
  //     let status = data['data']['status'];
  //     if (status) {
  //       this.logout();
  //     }
  //   });

  //   return await modal.present();
  // }

  async confirmLogout() {
    const modal = await this.modalCtrl.create({
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      component: ModalAlertComponent,
      cssClass: 'sheet-modal',
      componentProps: {
        title: 'Apakah anda yakin ingin keluar ?',
        btnTitleConfirm: 'Logout',
        imageAlert: '../../../../assets/images/ilustration/undraw_exit.svg',
      },
    });

    modal.onDidDismiss().then((data) => {
      let status = data['data']['status'];

      if (status) {
        this.logout();
      }
    });

    return await modal.present();
  }
}
