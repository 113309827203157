import { Injectable } from '@angular/core';
import {
  NavController,
  ToastController,
  AlertController,
  PopoverController,
  ModalController,
  MenuController,
} from '@ionic/angular';
import * as moment from 'moment';
import { ModalImageComponent } from '../_components/modal-image/modal-image.component';
import { ModalMessageComponent } from '../_components/modal-message/modal-message.component';
import { ModalKategoriComponent } from '../_components/modal-kategori/modal-kategori.component';

@Injectable({
  providedIn: 'root',
})
export class HelpersService {
  enlarge_popover: any;

  constructor(
    public navCtrl: NavController,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    public toastCtrl: ToastController,
    private modalCtrl: ModalController,
    
  ) {}

  goTo(page) {
    this.navCtrl.navigateRoot(page);
  }

  goForward(page) {
    this.navCtrl.navigateForward(page);
  }

  goForwardParam(page, param) {
    this.navCtrl.navigateForward([page, param]);
  }

  goBack(page) {
    this.navCtrl.navigateBack(page);
  }

  async presentToast(message: any, position: any = 'top', color: any = 'dark') {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 2000,
      position: position,
      color: color,
    });

    toast.present();
  }

  async presentAlert(header, msg) {
    const alert = await this.alertCtrl.create({
      header: header,
      message: msg,
      buttons: ['Ok'],
    });

    await alert.present();
  }

  async presentToastCustom(msg, color = '') {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000,
      position: 'top',
      color: color,
      cssClass: 'custom-toast-style',
    });

    await toast.present();
  }

  substr(text = '') {
    let count_char = text.slice().length;
    if (count_char > 150) {
      return text.substring(0, 150) + '...';
    }
    return text;
  }

  dateDiff(date) {
    moment.locale('id');
    return moment(date).startOf('day').fromNow();
  }

  dateFormat(date) {
    moment.locale('id');

    let date_format = moment(date, 'YYYY-MM-DD HH:mm:ss');

    return date_format.format('LLLL');
  }

  file(file) {
    return file[0].url;
  }

  getHiddenPhoneNumber(number: any) {
    const firstDigits = number.slice(0, 3);
    const hiddenDigits = number.slice(3, -3).replace(/\d/g, '*');
    const lastDigits = number.slice(-3);
    return firstDigits + hiddenDigits + lastDigits;
  }

  getHiddenEmail(email: any) {
    const [username, domain] = email.split('@');
    const hiddenUsername = username.slice(3).replace(/./g, '*');
    const visibleUsername = username.slice(0, 3);
    return visibleUsername + hiddenUsername + '@' + domain;
  }

  async modalImage(img) {

    let imgDummy =
      'https://thumbs.dreamstime.com/z/colorful-abstract-batik-banner-template-dummy-text-web-design-landing-page-print-material-195149182.jpg';
    const modal = await this.modalCtrl.create({
      component: ModalImageComponent,
      cssClass: 'transparent-modal',
      componentProps: {
        // img: imgDummy,
        img,
      },
    });

    return await modal.present();
  }

  async modalMessage(message:any, image:any) {
    const modal = await this.modalCtrl.create({
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      component: ModalMessageComponent,
      cssClass: 'sheet-modal',
      componentProps: {
        title: message,
        imageAlert: image,
      },
    });

    return await modal.present();
  }

  _search() {
    this.navCtrl.navigateForward('/pengaduan-search');
  }

  async openModal() {
    const modal = await this.modalCtrl.create({
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      component: ModalKategoriComponent,
    });
    return await modal.present();
  }
}
