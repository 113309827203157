import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvService {
  currentUrlSubject: BehaviorSubject<any>;
  isDemo: boolean = false;
  production : any = "https://sidumas.badungkab.go.id/api/";
  demo : any = "https://demo-sidumas.badungkab.go.id/api/";

  constructor() {
    this.currentUrlSubject = new BehaviorSubject<any>(
      localStorage.getItem('api_url')
    );
  }

  get url(): any {
    return this.currentUrlSubject.value;
  }

  setProd() {
    localStorage.setItem('api_url', `${environment.url.production}`);
    this.currentUrlSubject = new BehaviorSubject<any>(
      localStorage.getItem('api_url')
    );
  }

  setDemo() {
    localStorage.setItem('api_url', `${environment.url.demo}`);
    this.currentUrlSubject = new BehaviorSubject<any>(
      localStorage.getItem('api_url')
    );
  }
}
