import { Component, OnInit } from '@angular/core';
import { DarkModeService } from 'src/app/_services/dark-mode.service';

@Component({
  selector: 'app-modal-darkmode',
  templateUrl: './modal-darkmode.component.html',
  styleUrls: ['./modal-darkmode.component.scss'],
})
export class ModalDarkmodeComponent implements OnInit {
  
  currentStyle : any = this.darkMode.currentModeValue;

  constructor(
    private darkMode : DarkModeService
  ) { }

  ngOnInit() {
  }
  
  onChange(ev) {
    if(ev.detail.value === 'dark') {
      this.darkMode.darkMode();
      this.currentStyle = 'dark';
    } else if(ev.detail.value === 'light') {
      this.darkMode.lightMode();
      this.currentStyle = 'light';
    } else {
      this.darkMode.autoMode();
      this.currentStyle = 'auto';
    }
  }

}
