import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AlertService } from './alert.service';
import { EnvService } from './env.service';
import { HelpersService } from './helpers.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  currentToken: BehaviorSubject<any>;

  currentUserSubject: BehaviorSubject<any>;
  currentUser: Observable<any>;

  loginStatusSubject: BehaviorSubject<any>;
  loginStatus: Observable<any>;

  fcmtoken: any;

  constructor(
    private http: HttpClient,
    private env: EnvService,
    public helper: HelpersService,
    public alertService: AlertService
  ) {
    this.currentToken = new BehaviorSubject<any>(localStorage.getItem('token'));
    this.currentUserSubject = new BehaviorSubject<any>(
      localStorage.getItem('current_user')
      // JSON.parse(localStorage.getItem('current_user'))
    );
    this.currentUser = this.currentUserSubject.asObservable();

    this.loginStatusSubject = new BehaviorSubject<any>(
      localStorage.getItem('loginStatus')
    );
    this.loginStatus = this.loginStatusSubject.asObservable();
  }

  storeUser(data: any) {
    localStorage.setItem('current_user', data);
    this.currentUserSubject.next(data);
  }

  storeLoginStatus(status: any) {
    localStorage.setItem('loginStatus', status);
    this.loginStatusSubject.next(status);
  }

  get currentTokenValue(): any {
    return this.currentToken.value;
  }

  public get currentUserValue(): any {
    return JSON.parse(this.currentUserSubject.value);
  }

  public get isLoggedin(): boolean {
    return this.loginStatusSubject.value;
  }

  login(email: String, password: String) {
    // this.getFcm();

    let data = {
      email: email,
      password: password,
      // token : this.fcmtoken
    };

    return this.http.post(this.env.url + 'login', data).pipe(
      map((res) => {
        
        if (res && res['success']) {
          localStorage.setItem('token', res['token']);
          this.currentToken.next(res['token']);
          this.storeLoginStatus(true);
          return res;
        } else {
          this.helper.presentToast(
            res['message'],
            'bottom',
            'danger'
          );
        }

        return res;
      })
    );
  }

  register_old(
    name: String,
    no_telp: Number,
    email: String,
    password: String,
    c_password: String
  ) {
    // this.getFcm();

    let data = {
      name: name,
      email: email,
      no_telp: no_telp,
      password: password,
      password_confirmation: c_password,
      // token: this.fcmtoken
    };

    return this.http.post(this.env.url + 'register', data);
  }

  register(data: any) {
    // this.getFcm();

    return this.http.post(this.env.url + 'register', data);
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('current_user');
    localStorage.removeItem('loginStatus');
    this.helper.goTo('/auth/login');
    this.currentToken.next(null);
    this.currentUserSubject.next(null);
    this.loginStatusSubject.next(false);
  }

  // saveFcm(token) {
  //   this.storage.set('sidumas_fcmtoken', token);
  //   this.fcmtoken = token;
  // }

  // getFcm() {
  //   return this.storage.get('sidumas_fcmtoken')
  //   .then(data => {
  //     this.fcmtoken = data;
  //   });
  // }

  requestUserDetail() {
    return this.http.get(this.env.url + 'user').pipe(
      map((res) => {
        if (res) {
          this.storeUser(JSON.stringify(res));
        }
        return res;
      })
    );
  }
}
