import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[appMissingImage]',
})
export class MissingImageDirective {
  @Input() appMissingImage: string;

  constructor(private eRef: ElementRef) {}

  @HostListener('error')
  handleBrokenImage() {
    const element: HTMLImageElement = <HTMLImageElement>this.eRef.nativeElement;
    element.src =
      this.appMissingImage ||
      'https://placehold.co/600x400/EEE/31343C?font=montserrat&text=Oops,%20Image%20not%20Found.';
  }
}
