import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail-aduan-skeleton',
  templateUrl: './detail-aduan-skeleton.component.html',
  styleUrls: ['./detail-aduan-skeleton.component.scss'],
})
export class DetailAduanSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
