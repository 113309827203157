import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ModalKategoriComponent } from './modal-kategori/modal-kategori.component';
import { ModalDarkmodeComponent } from './modal-darkmode/modal-darkmode.component';
import { MenuComponent } from './menu/menu.component';
import { AduanCardComponent } from './aduan-card/aduan-card.component';
import { ModalPlacesComponent } from './modal-places/modal-places.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapsComponent } from './maps/maps.component';
import { HomeSkeletonComponent } from './skeleton/home-skeleton/home-skeleton.component';
import { KategoriSkeletonComponent } from './skeleton/kategori-skeleton/kategori-skeleton.component';
import { LaporPengaduanSkeletonComponent } from './skeleton/lapor-pengaduan-skeleton/lapor-pengaduan-skeleton.component';
import { LaporReviewSkeletonComponent } from './skeleton/lapor-review-skeleton/lapor-review-skeleton.component';
import { ModalCallComponent } from './modal-call/modal-call.component';
import { DetailAduanSkeletonComponent } from './skeleton/detail-aduan-skeleton/detail-aduan-skeleton.component';
import { ModalShareAduanComponent } from './modal-share-aduan/modal-share-aduan.component';
import { ModalImageComponent } from './modal-image/modal-image.component';
import { ModalShareWebComponent } from './modal-share-web/modal-share-web.component';
import { ModalCallSkeletonComponent } from './skeleton/modal-call-skeleton/modal-call-skeleton.component';
import { NotifikasiSkeletonComponent } from './skeleton/notifikasi-skeleton/notifikasi-skeleton.component';
import { AduanBlockCardComponent } from './aduan-block-card/aduan-block-card.component';
import { AduanBlockCardSkeletonComponent } from './skeleton/aduan-block-card-skeleton/aduan-block-card-skeleton.component';
import { AccountBlockSkeletonComponent } from './skeleton/account-block-skeleton/account-block-skeleton.component';
import { ProfileSkeletonComponent } from './skeleton/profile-skeleton/profile-skeleton.component';
import { ModalAlertComponent } from './modal-alert/modal-alert.component';
import { DirectiveModule } from '../_directives/directive.module';
import { ModalMessageComponent } from './modal-message/modal-message.component';
import { ModalAvatarComponent } from './modal-avatar/modal-avatar.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';

const _COMPONNENTS = [
  ModalKategoriComponent,
  ModalDarkmodeComponent,
  MenuComponent,
  AduanCardComponent,
  ModalPlacesComponent,
  ModalCallComponent,
  ModalShareAduanComponent,
  ModalImageComponent,
  ModalShareWebComponent,
  MapsComponent,
  HomeSkeletonComponent,
  KategoriSkeletonComponent,
  LaporPengaduanSkeletonComponent,
  LaporReviewSkeletonComponent,
  DetailAduanSkeletonComponent,
  ModalCallSkeletonComponent,
  NotifikasiSkeletonComponent,
  AduanBlockCardComponent,
  AduanBlockCardSkeletonComponent,
  AccountBlockSkeletonComponent,
  ProfileSkeletonComponent,
  ModalAlertComponent,
  ModalMessageComponent,
  ModalAvatarComponent,
  HeaderMenuComponent,
];
@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    DirectiveModule,
  ],
  declarations: [_COMPONNENTS],
  exports: [_COMPONNENTS],
})
export class ComponentsModule {}
