import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { PermissionInstanGuard } from './_guards/permision-instan.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'tabs',
    pathMatch: 'full',
  },
  {
    path: 'tabs',
    loadChildren: () =>
      import('./_pages/tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'pengaduan-search',
    loadChildren: () =>
      import('./_pages/pengaduan-search/pengaduan-search.module').then(
        (m) => m.PengaduanSearchPageModule
      ),
  },
  {
    path: 'home-backup',
    loadChildren: () =>
      import('./_pages/tabs/home-backup/home-backup.module').then(
        (m) => m.HomeBackupPageModule
      ),
  },
  {
    path: 'lapor-pengaduan/:action/:id',
    loadChildren: () =>
      import(
        './_pages/pengaduan-lapor/lapor-pengaduan/lapor-pengaduan.module'
      ).then((m) => m.LaporPengaduanPageModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'lapor-file',
    loadChildren: () =>
      import('./_pages/pengaduan-lapor/lapor-file/lapor-file.module').then(
        (m) => m.LaporFilePageModule
      ),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'lapor-review',
    loadChildren: () =>
      import('./_pages/pengaduan-lapor/lapor-review/lapor-review.module').then(
        (m) => m.LaporReviewPageModule
      ),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'pengaduan-dikirim',
    loadChildren: () =>
      import(
        './_components/other/ss-pengaduan-dikirim/ss-pengaduan-dikirim.module'
      ).then((m) => m.SsPengaduanDikirimPageModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'detail-aduan/:id',
    loadChildren: () =>
      import('./_pages/detail-aduan/detail-aduan.module').then(
        (m) => m.DetailAduanPageModule
      ),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'profile-edit',
    loadChildren: () =>
      import('./_pages/profile/profile-edit/profile-edit.module').then(
        (m) => m.ProfileEditPageModule
      ),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import(
        './_pages/profile/profile-change-password/profile-change-password.module'
      ).then((m) => m.ProfileChangePasswordPageModule),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: 'account-block',
    loadChildren: () =>
      import(
        './_pages/profile/profile-account-block/profile-account-block.module'
      ).then((m) => m.ProfileAccountBlockPageModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'riwayat-pengaduan',
    loadChildren: () =>
      import('./_pages/riwayat-pengaduan/riwayat-pengaduan.module').then(
        (m) => m.RiwayatPengaduanPageModule
      ),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'public-user/:id',
    loadChildren: () =>
      import('./_pages/public-user/public-user.module').then(
        (m) => m.PublicUserPageModule
      ),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'monitoring',
    loadChildren: () =>
      import('./_pages/monitoring/monitoring.module').then(
        (m) => m.MonitoringPageModule
      ),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'pengaduan-ditandai',
    loadChildren: () =>
      import('./_pages/pengaduan-ditandai/pengaduan-ditandai.module').then(
        (m) => m.PengaduanDitandaiPageModule
      ),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./_pages/about/about.module').then((m) => m.AboutPageModule),
  },
  {
    path: 'bantuan',
    loadChildren: () =>
      import('./_pages/bantuan/bantuan.module').then(
        (m) => m.BantuanPageModule
      ),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'status-pengaduan',
    loadChildren: () =>
      import('./_pages/status-pengaduan/status-pengaduan.module').then(
        (m) => m.StatusPengaduanPageModule
      ),
  },
  {
    path: 'whistle-desc',
    loadChildren: () =>
      import('./_pages/whistle-blowing/whistle-desc/whistle-desc.module').then(
        (m) => m.WhistleDescPageModule
      ),
  },
  {
    path: 'whistle-form',
    loadChildren: () =>
      import('./_pages/whistle-blowing/whistle-form/whistle-form.module').then(
        (m) => m.WhistleFormPageModule
      ),
  },
  {
    path: 'whistle-review',
    loadChildren: () =>
      import(
        './_pages/whistle-blowing/whistle-review/whistle-review.module'
      ).then((m) => m.WhistleReviewPageModule),
  },
  {
    path: 'instan-desc',
    loadChildren: () =>
      import('./_pages/pengaduan-instan/instan-desc/instan-desc.module').then(
        (m) => m.InstanDescPageModule
      ),
    canActivate: [PermissionInstanGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'instan-form/:action/:id',
    loadChildren: () =>
      import('./_pages/pengaduan-instan/instan-form/instan-form.module').then(
        (m) => m.InstanFormPageModule
      ),
    canActivate: [PermissionInstanGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'instan-review',
    loadChildren: () =>
      import(
        './_pages/pengaduan-instan/instan-review/instan-review.module'
      ).then((m) => m.InstanReviewPageModule),
      canActivate: [PermissionInstanGuard],
      runGuardsAndResolvers: 'always',
  },
  {
    path: 'instan-biodata',
    loadChildren: () =>
      import(
        './_pages/pengaduan-instan/instan-biodata/instan-biodata.module'
      ).then((m) => m.InstanBiodataPageModule),
      canActivate: [PermissionInstanGuard],
      runGuardsAndResolvers: 'always',
  },
  {
    path: 'instan-kategori',
    loadChildren: () =>
      import(
        './_pages/pengaduan-instan/instan-kategori/instan-kategori.module'
      ).then((m) => m.InstanKategoriPageModule),
      canActivate: [PermissionInstanGuard],
      runGuardsAndResolvers: 'always',
  },
  {
    path: 'instan-file',
    loadChildren: () =>
      import('./_pages/pengaduan-instan/instan-file/instan-file.module').then(
        (m) => m.InstanFilePageModule
      ),
      canActivate: [PermissionInstanGuard],
      runGuardsAndResolvers: 'always',
  },
  {
    path: 'instan-otp',
    loadChildren: () =>
      import('./_pages/pengaduan-instan/instan-otp/instan-otp.module').then(
        (m) => m.InstanOtpPageModule
      ),
      canActivate: [PermissionInstanGuard],
      runGuardsAndResolvers: 'always',
  },
  {
    path: 'auth/login',
    loadChildren: () =>
      import('./_pages/auth/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'auth/register',
    loadChildren: () =>
      import('./_pages/auth/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
  },
  {
    path: 'auth/forgot-password',
    loadChildren: () =>
      import('./_pages/auth/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: 'auth/password-otp',
    loadChildren: () =>
      import('./_pages/auth/password-otp/password-otp.module').then(
        (m) => m.PasswordOtpPageModule
      ),
  },
  {
    path: 'auth/new-repassword',
    loadChildren: () =>
      import('./_pages/auth/new-repassword/new-repassword.module').then(
        (m) => m.NewRepasswordPageModule
      ),
  },
  {
    path: 'register-success',
    loadChildren: () =>
      import('./_components/other/ss-register/ss-register.module').then(
        (m) => m.SsRegisterPageModule
      ),
  },
  {
    path: 'location-disable',
    loadChildren: () => import('./_components/other/location-disable/location-disable.module').then( m => m.LocationDisablePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,useHash: false }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
