import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DarkModeService } from './_services/dark-mode.service';
import { EnvService } from './_services/env.service';
import { App as CapacitorApp } from '@capacitor/app';
import { HttpClient } from '@angular/common/http';
import { AlertService } from './_services/alert.service';
import { Capacitor } from '@capacitor/core';
import { PushNotifService } from './_services/push-notif.service';
import { AuthService } from './_services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private darkMode: DarkModeService,
    private env: EnvService,
    private http: HttpClient,
    private alert: AlertService,
    private pushNotif: PushNotifService,
    private authService: AuthService,
  ) {
    this.initializeApp();
    this.backButton();
  }

  async initializeApp() {
    if (Capacitor.isNativePlatform()) {
      CapacitorApp.getInfo().then(info => {
        this.getApiUrl(info.version);
      });

      if (this.authService.currentTokenValue != null) {
        this.pushNotif.initPushNotif();
      }
    }else{
      localStorage.setItem('api_url', `${environment.url.demo}`);
      this.env.currentUrlSubject = new BehaviorSubject<any>(
        localStorage.getItem('api_url')
      );
    }
    
    let currentMode = this.darkMode.currentModeValue;
    if (currentMode) {
      if (currentMode === 'dark') {
        this.darkMode.darkMode();
      } else if (currentMode === 'light') {
        this.darkMode.lightMode();
      } else {
        this.darkMode.autoMode();
      }
    } else {
      this.darkMode.autoMode();
    }
  }

  async getApiUrl(version_number){
    const platform = await Capacitor.getPlatform();
    this.http.get(this.env.production + 'demo').subscribe((arg) => {      
      if(arg && arg['success']){
        let data = arg['data'];
        if(version_number == data.v){
          if((platform === 'ios' && data.isDemoIOS == 1) || (platform === 'android' && data.isDemoAndroid == 1)){
            this.env.setDemo();
            this.alert.presentToast('Demo version ' + data.v, '');
          } else {
            this.env.setProd();
          }
        } else {
          this.env.setProd();
        }
      } else {
        this.env.setProd();
      }
    });
  }

  async backButton(){
    CapacitorApp.addListener('backButton', ({ canGoBack }) => {
      if (!canGoBack) {
        CapacitorApp.exitApp();
      } else {
        window.history.back();
      }
    });
  }

}
