import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { AuthService } from 'src/app/_services/auth.service';
import { HelpersService } from 'src/app/_services/helpers.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {
  showSearch: boolean = false;
  constructor(
    public helper : HelpersService,
    public auth: AuthService,
    private menu: MenuController,
  ) {}

  ngOnInit() {}

  openMenu() {
    this.menu.open('sideMenu');
  }

}
