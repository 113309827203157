import {
  ContentChild,
  Directive,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { IonContent, IonToolbar } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Directive({
  selector: 'ion-header[appFadeHeader]',
})
export class FadeHeaderDirective implements OnInit {
  @Input() ionContentRef: IonContent | undefined;
  @ContentChild(IonToolbar, { static: true }) toolbar: IonToolbar | undefined;
  toolbarEl: HTMLElement | undefined;
  scrollArea: HTMLElement | undefined;
  scrollSubs: Subscription | undefined;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  async ngOnInit() {
    this.scrollArea = await this.ionContentRef.getScrollElement();
    this.toolbarEl = (this.toolbar as any).el as HTMLElement;

    if (this.toolbarEl) {
      this.renderer.addClass(this.toolbarEl, 'transparent');
    }

    this.scrollSubs = this.ionContentRef.ionScroll.subscribe((val) => {
      this.changeBackground();
    });
  }

  get toolbarBackground() {
    return this.toolbarEl.shadowRoot.children[0];
  }

  changeBackground() {
    if (this.scrollArea.scrollTop > 100) {
      this.toolbarEl.classList.add('show');
      this.toolbarEl.classList.remove('transparent');
    } else {
      this.toolbarEl.classList.remove('show');
      this.toolbarEl.classList.add('transparent');
    }
  }
}
