import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FadeHeaderDirective } from './fade-header.directive';
import { MissingImageDirective } from './missing-image.directive';

@NgModule({
  declarations: [FadeHeaderDirective, MissingImageDirective],
  imports: [CommonModule],
  exports: [FadeHeaderDirective, MissingImageDirective],
})
export class DirectiveModule {}
