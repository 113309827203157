import { PushNotifications } from '@capacitor/push-notifications';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotifService {
  constructor(
    private router: Router,
    private req:RequestService,
  ) {}

  initPushNotif() {
    this.checkPermission();
    this.registerPushNotif();
  }

  async checkPermission() {
    let permstat = await PushNotifications.checkPermissions();

    if (permstat.receive === 'prompt') {
      await PushNotifications.requestPermissions();
    }

    if (permstat.receive !== 'granted') {
      console.error('Permission Denied !');
    }

    await PushNotifications.register();
  }

  async registerPushNotif() {
    await PushNotifications.addListener('registration', (token) => {
      let formData = {
        fcm_token: token.value,
      };
      this.req.requestPost('fcm-token', formData).subscribe(
        (ress) => {
        }
      );
      console.info('Registration token: ', token.value);
    });

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {

        if (
          typeof notification.title != 'undefined' &&
          typeof notification.body != 'undefined'
        ) {

          if (Capacitor.getPlatform() === 'android') {
            if (
              typeof notification.data.notification_toaster == 'undefined' ||
              (typeof notification.data.notification_toaster != 'undefined' &&
                notification.data.notification_toaster != '0')
            ) {}
          }
        }

      }
    );

    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification) => {
        // this.router.navigate([`/tabs/home`]);
        this.router.navigate([notification.notification.data.redirect]);
      }
    );
  }

  resetBadgeCount() {
    PushNotifications.removeAllDeliveredNotifications();
  }
}
