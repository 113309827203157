import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-call-skeleton',
  templateUrl: './modal-call-skeleton.component.html',
  styleUrls: ['./modal-call-skeleton.component.scss'],
})
export class ModalCallSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
