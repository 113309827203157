import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {
  
  preferDark : any = window.matchMedia('(prefers-color-scheme: dark)');
  
  currentModeSubject : BehaviorSubject<any>;
  currentMode : Observable<any>;
  
  
  private renderer : Renderer2;
  
  styleListener : any;

  constructor(
    private rendererFactory : RendererFactory2
  ) {
    this.currentModeSubject = new BehaviorSubject<any>(localStorage.getItem('styleMode'));
    this.currentMode = this.currentModeSubject.asObservable();
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  
  public get currentModeValue() {
    return this.currentModeSubject.value;
  }
  
  styleChanged() {
    
  }
  
  lightMode(){
    this.preferDark.removeListener(this.handleAutoListener);
    localStorage.setItem('styleMode', 'light');
    this.currentModeSubject.next('light');
    this.renderer.setAttribute(document.body, 'color-theme', 'light');
  }
  
  darkMode(){
    this.preferDark.removeListener(this.handleAutoListener);
    localStorage.setItem('styleMode', 'dark');
    this.currentModeSubject.next('dark');
    this.renderer.setAttribute(document.body, 'color-theme', 'dark');
  }
  
  autoMode(){
    localStorage.setItem('styleMode', 'auto');
    this.currentModeSubject.next('auto');
    if(this.preferDark.matches) {
      this.renderer.setAttribute(document.body, 'color-theme', 'dark');
    } else {
      this.renderer.setAttribute(document.body, 'color-theme', 'light');
    }
    
    this.autoListener();
  }
  
  autoListener() {
    this.preferDark.addListener(this.handleAutoListener);
  }
  
  handleAutoListener(e) {
    if(e.matches) {
      document.body.setAttribute('color-theme', 'dark');
    } else {
      document.body.setAttribute('color-theme', 'light');
    }
  }
}
