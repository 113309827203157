import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from './env.service';
import { Platform } from '@ionic/angular';
import { AuthService } from './auth.service';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  token: any;
  headers: any;
  notif: any = { news: 0 };
  user: any = null;
  banners: any = { get: 0, data: [], popover: {} };
  pengaduan: any;
  loadingOption: any = {
    spinner : null,
    backdropDismiss : false,
    message : '<div class="sidumas-loading lds-ring"><div></div><div></div><div></div><div></div><img src="assets/images/icon-min-200.png"></div>',
    cssClass: 'sidumas-loading',
    keyboardClose: true,
    animated: true,
  };
  versionNumber: any = '';
  versionCode: any = '';

  constructor(
    private http        : HttpClient,
    private env         : EnvService,
    public platform     : Platform,
    private authService : AuthService,
    public alertService : AlertService
  ) {
  }

  ngOnInit(): void {
  }

  getListPengaduan(category = '', search = '', page = '') {

    return this.http.get(this.env.url + 'pengaduan/list?cid=' + category + '&s=' + search + '&page=' + page)
      .pipe(
      );
  }

  getDetailPengaduan(id) {

    return this.http.get(this.env.url + 'pengaduan/detail?pengaduan_id=' + id)
      .pipe(
      );
  }

  getComments(id) {
    return this.http.get(this.env.url + 'pengaduan/komentar?pengaduan_id=' + id)
      .pipe(
      );
  }

  getTanggapans(id) {
    return this.http.get(this.env.url + 'pengaduan/tindaklanjut?pengaduan_id=' + id)
      .pipe();
  }

  getKategori() {

    return this.http.get(this.env.url + 'pengaduan/menu')
      .pipe(

      );
  }

  getKategoriName(id) {

    return this.http.get(this.env.url + 'pengaduan/category?id=' + id)
      .pipe();
  }

  getAllCategory(){

    return this.http.get(this.env.url + 'pengaduan/all-category')
      .pipe();
  }

  getUser() {

    return this.http.get(this.env.url + 'user')
      .pipe();
  }

  getUserCache(force = false) {
    if (this.user == null || force) {

      this.user = this.http.get(this.env.url + 'user')
        .subscribe(data => {
          this.user = data;
          return this.user;
        }, err => {
          //error getting user information
        });
    }
    return this.user;
  }

  checkUser(user_id) {

    let data = {
      id: user_id
    }
    return this.http.post(this.env.url + 'pengaduan/check-user', data)
      .pipe(

      )
  }

  updateProfileUser(updated_information) {

    let data = updated_information;

    return this.http.post(this.env.url + 'pengaduan/update-user', data)
      .pipe();
  }

  addPengaduan(data) {

    return this.http.post(this.env.url + 'pengaduan/add', data)
      .pipe();
  }

  getProject(opd_id) {

    return this.http.get(this.env.url + 'pengaduan/projectppl?opd_id=' + opd_id)
      .pipe();
  }

  detailProject(project_id) {

    return this.http.get(this.env.url + 'pengaduan/detailproject?project_id=' + project_id)
      .pipe();
  }

  postKomentar(data) {

    return this.http.post(this.env.url + 'pengaduan/add-komentar', data)
      .pipe();
  }

  postTanggapan(data) {

    return this.http.post(this.env.url + 'pengaduan/tanggapan', data)
      .pipe();
  }

  seeUserStatus(user_id) {

    return this.http.get(this.env.url + 'pengaduan/lihat-user?id=' + user_id)
      .pipe();
  }

  updatePassword(data) {

    return this.http.post(this.env.url + 'pengaduan/update-password', data)
      .pipe();
  }

  addVote(data) {

    return this.http.post(this.env.url + 'pengaduan/add-vote', data)
      .pipe();
  }

  checkVoted(user_id, pengaduan_id) {
    return this.http.get(this.env.url + 'pengaduan/checkvote?user_id=' + user_id + '&pengaduan_id=' + pengaduan_id)
      .pipe();

  }

  myList(user_id, category = '', page = '') {

    return this.http.get(this.env.url + 'pengaduan/listsaya?user_id=' + user_id + '&cid=' + category + '&page=' + page)
      .pipe();
  }

  /*
    notifications
  */
  getNotifs(user_id, page = 0, first = 0) {

    return this.http.get(this.env.url + 'pengaduan/notifikasi?limit=20&page=' + page + '&id=' + user_id + '&first=' + first)
      .pipe();
  }

  getNewNotif() {

    this.http.get(this.env.url + 'pengaduan/notifikasi/new')
      .subscribe((data) => {
        this.notif = data;
      }, err => {
        //cannot get new notif
      });
  }

  getMenuCategories() {

    return this.http.get(this.env.url + 'pengaduan/menu-category')
      .pipe();
  }

  updateAvatar(data) {
    return this.http.post(this.env.url + 'pengaduan/update-avatar', data)
      .pipe();
  }

  getBanners(VersionNumber = "", VersionCode= "") {

    return this.http.get(this.env.url + 'ref/banners?vc='+VersionCode+'&v='+VersionNumber)
      .pipe();
  }

  forgotPassword(data) {

    return this.http.post(this.env.url + 'lupapassword', data)
      .pipe();
  }

  getDesaID(lat: any, lng: any) {

    let data = {
      'lat'   : lat,
      'lng'   : lng
    }
    return this.http.get(this.env.url + 'pengaduan/desa?lat='+lat+'&lng='+lng)
      .pipe();
  }

  deleteFile(file_id, pengaduan_id){

    let data = {
      'id'  : file_id,
      'pengaduan_id' : pengaduan_id
    }

    return this.http.post(this.env.url + 'pengaduan/remove-file', data)
    .pipe();
  }

  updatePengaduan(data) {

    return this.http.post(this.env.url + 'pengaduan/edit', data)
      .pipe();
  }

  deletePengaduan(data) {

    return this.http.post(this.env.url + 'pengaduan/remove-pengaduan', data)
      .pipe();
  }

  addReportPengaduan(data){

    return this.http.post(this.env.url + 'report/addpengaduan', data)
    .pipe();
  }

  addBlockedUser(data) {

    return this.http.post(this.env.url + 'blocks/add', data)
    .pipe();
  }

  removeBlockedUser(data){

    return this.http.post(this.env.url + 'blocks/remove', data)
    .pipe();
  }

  getBlockedUsers(user_id){

    return this.http.get(this.env.url + 'blocks?user_id='+user_id)
    .pipe();
  }

  getUserProfile(user_id){

    return this.http.get(this.env.url + 'profil?id='+user_id)
    .pipe();
  }

  isBlocked(user_id, selected_user_id){

    return this.http.get(this.env.url + 'blocks/isblock?user_id='+user_id+'&selected_user='+selected_user_id)
    .pipe();
  }

  getDetailByTiket(tiket){
    return this.http.get(this.env.url + 'T/'+tiket)
    .pipe();
  }

  getPubComments(id) {
    return this.http.get(this.env.url + 'pub/pengaduan/komentar?pengaduan_id=' + id)
      .pipe(
      );
  }

  getPubTanggapans(id) {

    return this.http.get(this.env.url + 'pub/pengaduan/tindaklanjut?pengaduan_id=' + id)
      .pipe();
  }

  // updateFcmToken(){
  //   return this.http.post(this.env.url + 'user/fcm-token', {token: this.authService.fcmtoken})
  //   .pipe();
  // }
}