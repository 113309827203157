import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AlertService } from './alert.service';
import { EnvService } from './env.service';
import { HelpersService } from './helpers.service';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { App as CapacitorApp, AppState } from '@capacitor/app';
import { Geolocation } from '@capacitor/geolocation';
import { NavController } from '@ionic/angular';

export enum PermissionStatus {
    Prompt,
    Granted,
    Denied,
  }

@Injectable({
  providedIn: 'root',
})
export class PermissionService {

    public permissionStatus: BehaviorSubject<PermissionStatus> =
    new BehaviorSubject(PermissionStatus.Prompt);

  constructor(
    private http: HttpClient,
    private env: EnvService,
    public helper: HelpersService,
    public alertService: AlertService,
    private ngZone: NgZone,
    public navCtrl: NavController,
  ) {

    this.permissionStatus.asObservable();
  }

  initialize() {
    return this.checkPermission();
  }

  checkPermission(proceed_req: boolean = true) {
    return Geolocation.checkPermissions()
      .then((permission) => {
        if (
          permission.coarseLocation === 'granted' &&
          permission.location === 'granted'
        ) {
          this.updatePermissionStatus(PermissionStatus.Granted);
        } else if (
          permission.coarseLocation === 'denied' ||
          permission.location === 'denied'
        ) {
          this.updatePermissionStatus(PermissionStatus.Denied);
          // if (proceed_req) {
          //   this.handleDeniedLocationPermission();
          // }
          // this.handleDeniedLocationPermission();
        // } else {
        //   // if (proceed_req) {
        //   //   this.requestPermission();
        //   // }
        //   this.requestPermission();
        // }
        }
        return this.currentPermissionStatus;
      })
      .catch((err) => {
        return err;
      });
  }

  requestPermission() {
    return Geolocation.requestPermissions({
      permissions: ['location', 'coarseLocation'],
    })
      .then((result) => {
        if (
          result.coarseLocation === 'granted' &&
          result.location === 'granted'
        ) {
          this.updatePermissionStatus(PermissionStatus.Granted);
        } else if (
          result.coarseLocation === 'denied' ||
          result.location === 'denied'
        ) {
          this.updatePermissionStatus(PermissionStatus.Denied);
          // this.handleDeniedLocationPermission();
          
        } else {
          this.checkPermission();
        }
        // return result;
        return this.currentPermissionStatus;
      })
      .catch((err) => {
        return err;
      });
  }

  updatePermissionStatus(status: PermissionStatus) {
    this.permissionStatus.next(status);
  }

  public get currentPermissionStatus(): PermissionStatus {
    return this.permissionStatus.getValue();
  }

  handleDeniedLocationPermission() {
    this.navCtrl.navigateForward(['location-disable']);
  }

}
