import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-lapor-pengaduan-skeleton',
  templateUrl: './lapor-pengaduan-skeleton.component.html',
  styleUrls: ['./lapor-pengaduan-skeleton.component.scss'],
})
export class LaporPengaduanSkeletonComponent implements OnInit {
  @Input() is_mobile;
  constructor() {}

  ngOnInit() {}
}
