import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lapor-review-skeleton',
  templateUrl: './lapor-review-skeleton.component.html',
  styleUrls: ['./lapor-review-skeleton.component.scss'],
})
export class LaporReviewSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
