import { Component, Input, OnInit } from '@angular/core';
import { Aduan } from 'src/app/_interfaces/aduan';
import * as moment from 'moment';
import { NavController } from '@ionic/angular';
import { HelpersService } from 'src/app/_services/helpers.service';
import { SharedService } from 'src/app/_services/shared.service';
import { RequestService } from 'src/app/_services/request.service';
import { AuthService } from 'src/app/_services/auth.service';
import { AlertService } from 'src/app/_services/alert.service';

@Component({
  selector: 'app-aduan-card',
  templateUrl: './aduan-card.component.html',
  styleUrls: ['./aduan-card.component.scss'],
})
export class AduanCardComponent implements OnInit {
  @Input() data: Aduan;
  is_ditandai: boolean = false;
  is_like: boolean = false;
  dataUser: any;
  count_dukungans: any = 0;
  userToken: any;

  constructor(
    public navCtrl: NavController,
    public helper: HelpersService,
    private sharedService: SharedService,
    private req: RequestService,
    private authService: AuthService,
    public alertService: AlertService,
  ) {}

  ngOnInit() {
    this.count_dukungans =  this.data.likes_count;
    this.userToken = this.authService.currentTokenValue;
    if (this.userToken != null) {
      this.dataUser = this.authService.currentUserValue;
      this.checkDitandai();
      this.cekVote(this.data.id, this.dataUser.id);
    }
  }

  async ionViewWillEnter() {
    // this.count_dukungans =  this.data.likes_count;
    this.userToken = this.authService.currentTokenValue;
    if (this.userToken != null) {
      this.dataUser = this.authService.currentUserValue;
      this.checkDitandai();
      this.cekVote(this.data.id, this.dataUser.id);
    }
  }

  async bookmark(id: any) {
    if (this.dataUser === null) {
      await this.navCtrl.navigateRoot('auth/login');
    }else{
      let formData = {
        pengaduan_id: id,
      };
      await this.req.requestPost('pengaduan/ditandai', formData).subscribe(
        (res) => {
          if (res['success'] && res['ditandai']) {
            this.is_ditandai = true;
            this.alertService.presentToast('Pengaduan ditandai.');
          } else if (res['success'] && !res['ditandai']) {
            this.is_ditandai = false;
            this.alertService.presentToast('Batal menandai pengaduan ini.');
          }else{
            this.alertService.presentAlert(
              'Gagal Menyimpan Data',
              'Terjadi kesalahan saat menyimpan data'
            );
          }
        });
    }
  }

  async addVote(id) {
    if (this.dataUser === null) {
      await this.navCtrl.navigateRoot('auth/login');
    }else{

      let dataVote = {
        user_id: this.dataUser.id,
        pengaduan_id: id,
      };
      this.req.requestPost('pengaduan/add-vote', dataVote).subscribe((res) => {
        if (res['success'] && res['new_user']) {
          this.count_dukungans++;
          this.is_like = true;
          this.alertService.presentToast('Anda mendukung pengaduan ini.');
        } else if (res['success'] && !res['new_user']) {
          this.count_dukungans--;
          this.is_like = false;
          this.alertService.presentToast('Dukungan pengaduan ini dibatalkan.');
        } else {
          this.alertService.presentAlert(
            'Gagal Menyimpan Data',
            'Terjadi kesalahan saat menyimpan data'
          );
        }
      });
    }
  }

  checkDitandai(){
    if (this.data.is_ditandai != null) {
      this.is_ditandai = true;
    }else{
      this.is_ditandai = false;
    }
  }

  cekVote(pengaduan_id, user_id) {
    this.req
      .requestGet('pengaduan/cek-vote/' + pengaduan_id + '/' + user_id, '')
      .subscribe((res) => {
        if (res['result']) {
          this.is_like = true;
        } else {
          this.is_like = false;
        }
      });
  }

  seeDetail(id: any) {
    this.sharedService.pengaduan = this.data;
    this.navCtrl.navigateForward('detail-aduan/' + id);
  }

}
