import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss'],
})
export class ModalAlertComponent implements OnInit {
  @Input() title: any;
  @Input() desc: any = '';
  @Input() btnTitleConfirm: any;
  @Input() imageAlert: any;
  @Input() isConfirm: any = true;

  status: boolean = false;
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  async deletePhoto() {
    // let data = {
    //   status: true,
    // };
    this.status = true;

    let data = {
      status: true,
    };
    await this.modalCtrl.dismiss(data);
  }

  async close() {
    this.status = false;
    let data = {
      status: this.status,
    };
    await this.modalCtrl.dismiss(data);
  }
}
