import { Component, Input, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ModalController } from '@ionic/angular';
import { RequestService } from 'src/app/_services/request.service';
import { Clipboard } from '@capacitor/clipboard';
import { AlertService } from 'src/app/_services/alert.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-modal-share-web',
  templateUrl: './modal-share-web.component.html',
  styleUrls: ['./modal-share-web.component.scss'],
})
export class ModalShareWebComponent implements OnInit {
  @Input() url;
  formData: FormGroup;
  constructor(
    private req: RequestService,
    private modalCtrl: ModalController,
    public alertService: AlertService
  ) {}

  ngOnInit() {
    this.setForm();
  }

  setForm() {
    this.formData = new FormGroup({
      url_text: new FormControl(this.url),
    });

    // this.formData.get('url_text')?.setValue();
  }

  async close() {
    await this.modalCtrl.dismiss();
  }

  async copy() {
    await Clipboard.write({
      string: this.url,
    });

    this.alertService.presentToast('Copied');
    await this.modalCtrl.dismiss();
  }
}
