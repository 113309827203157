import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController, NavController } from '@ionic/angular';
import { AlertService } from 'src/app/_services/alert.service';
import { AuthService } from 'src/app/_services/auth.service';
import { EnvService } from 'src/app/_services/env.service';
import { RequestService } from 'src/app/_services/request.service';
import { ModalCallComponent } from '../modal-call/modal-call.component';
import { HelpersService } from 'src/app/_services/helpers.service';

@Component({
  selector: 'app-modal-kategori',
  templateUrl: './modal-kategori.component.html',
  styleUrls: ['./modal-kategori.component.scss'],
})
export class ModalKategoriComponent implements OnInit {
  @Input() modal_trigger;
  isModalOpen = false;
  data_menu: any = [];
  user: any = this.authService.currentUserValue;
  allowed_user: boolean = false;
  isLoading: boolean = true;
  userToken:any;
  userData:any;
  isInstan:boolean = true;

  constructor(
    private navCtrl: NavController,
    private req: RequestService,
    private env: EnvService,
    private authService: AuthService,
    private alertService: AlertService,
    public modalCtrl: ModalController,
    public helper: HelpersService
  ) {}

  ngOnInit() {
    this.isLoggedin();
  }

  ionViewDidEnter() {
    this.getMenu();
  }

  isLoggedin() {
    this.userToken = this.authService.currentTokenValue;
    if (this.userToken != null) {
      this.userData = this.authService.currentUserValue;
      if (this.userData['status'] > 0) {
        this.isInstan = false;
      }
    }
  }

  async getMenu() {
    await this.req.requestGet('pengaduan/menu', '').subscribe((res) => {
      this.data_menu = res;
      this.isLoading = false;
    });
  }

  gotoPengaduan() {
    this.navCtrl.navigateForward('lapor-pengaduan');
  }

  async goToWhistle() {
    this.navCtrl.navigateForward('whistle-desc');
    await this.modalCtrl.dismiss();
  }

  async goToInstan() {
    this.navCtrl.navigateForward('instan-desc');
    await this.modalCtrl.dismiss();
  }

  checkUser(): void {
    this.req.requestPost('pengaduan/check-user', this.user['id']).subscribe(
      (data) => {
        if (!data['success']) {
          this.allowed_user = false;
        } else {
          this.allowed_user = true;
        }
      },
      (err) => {}
    );
  }

  async goToLapor(id, event) {
    if (event) {
      let event_detail = event.split(',');
      if (event_detail[0] == 'call_number') {
      } else if (event_detail[0] == 'open_page') {
        this.navCtrl.navigateForward([
          event_detail[1],
          event_detail[3],
          event_detail[2],
        ]);
      } else {
        this.helper.presentToast(
          'No event',
          'bottom',
          'danger'
        );
      }
    } else {
      await this.modalCtrl.dismiss();
      this.navCtrl.navigateForward(['/lapor-pengaduan', id]);
    }
  }

  async goToLaporOld(id, event, menu_id) {
    if (event) {
      let event_detail = event.split(',');
      if (event_detail[0] == 'call_number') {
        this.callNumber(menu_id);
      } else if (event_detail[0] == 'open_page') {
        this.navCtrl.navigateForward([
          event_detail[1],
          event_detail[3],
          event_detail[2],
        ]);
      } else {
        console.log('no event');
      }
    } else {
      await this.modalCtrl.dismiss();
      this.navCtrl.navigateForward(['/lapor-pengaduan/add/', id]);
    }
  }

  async callNumber(id) {
    const modal = await this.modalCtrl.create({
      initialBreakpoint: 0.5,
      breakpoints: [0, 0.5],
      component: ModalCallComponent,
      componentProps: {
        menu_id: id,
      },
    });

    return await modal.present();
  }
}
