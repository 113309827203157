import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.scss'],
})
export class ModalMessageComponent implements OnInit {
  @Input() title: any;
  @Input() btnTitleConfirm: any;
  @Input() imageAlert: any;

  status: boolean = false;
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  async close() {
    this.status = false;
    let data = {
      status: this.status,
    };
    await this.modalCtrl.dismiss(data);
  }

}
