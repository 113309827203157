import { Component, Input, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { Geolocation } from '@capacitor/geolocation';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss'],
})
export class MapsComponent implements OnInit {
  @Input() lat;
  @Input() lng;
  map: any;
  markers = [];
  customMarkerIcon = L.icon({
    iconUrl: 'assets/icon/svg/ico-solid-lokasi.svg',
    iconSize: [32, 32],
  });
  markeropts = {
    icon: this.customMarkerIcon,
  };
  constructor(public modalCtrl: ModalController) {}

  ngOnInit() {}

  ionViewDidEnter() {
    this.initLeafletMap();
    this.tryGeoloc();
  }

  async initLeafletMap() {
    this.map = new L.Map('map').setView([-8.6027717, 115.1764153], 34);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);
  }

  async tryGeoloc() {
    // let coordinates = await Geolocation.getCurrentPosition();

    let marker = L.marker([this.lat, this.lng], this.markeropts);
    this.markers.push(marker);
    this.clearMarkers();
    this.addMarkers(this.lat, this.lng);
  }

  addMarkers(lat, lng) {
    this.clearMarkers();
    let marker = L.marker([lat, lng], this.markeropts);
    marker.addTo(this.map);
    marker.bindPopup('<b> Lokasi pengaduan </b>').openPopup();
    this.map.panTo([lat, lng]);
    this.markers.push(marker);
    marker.on('dragend', () => {
      this.lat = marker.getLatLng().lat;
      this.lng = marker.getLatLng().lng;
    });
  }

  clearMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.map.removeLayer(this.markers[i]);
    }

    this.markers = [];
  }

  async closeModal() {
    await this.modalCtrl.dismiss();
  }
}
