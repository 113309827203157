import { Component, OnInit, Input } from '@angular/core';
import {
  AlertController,
  ModalController,
  NavController,
} from '@ionic/angular';
import { AlertService } from 'src/app/_services/alert.service';
import { LoadingService } from 'src/app/_services/loading.service';
import { RequestService } from 'src/app/_services/request.service';
import { Share } from '@capacitor/share';
import { Capacitor } from '@capacitor/core';
import { ModalShareWebComponent } from '../modal-share-web/modal-share-web.component';
import { ModalAlertComponent } from '../modal-alert/modal-alert.component';
import { ModalMessageComponent } from '../modal-message/modal-message.component';
import { HelpersService } from 'src/app/_services/helpers.service';

@Component({
  selector: 'app-modal-share-aduan',
  templateUrl: './modal-share-aduan.component.html',
  styleUrls: ['./modal-share-aduan.component.scss'],
})
export class ModalShareAduanComponent implements OnInit {
  @Input() pengaduan: any;
  @Input() current_user: any;
  constructor(
    public modalCtrl: ModalController,
    private loader: LoadingService,
    public alertCtrl: AlertController,
    public alertService: AlertService,
    private req: RequestService,
    public navCtrl: NavController,
    public helper: HelpersService,
  ) {}

  ngOnInit() {}

  async share() {
    let data = this.pengaduan;

    if (Capacitor.isNativePlatform()) {
      await Share.share({
        title: 'Sidumas',
        text: null,
        url: 'https://sidumas.badungkab.go.id/T/' + data.no_tiket,
        dialogTitle: 'Bagikan Pengaduan Sidumas',
      });
    } else {
      let url = 'https://sidumas.badungkab.go.id/pengaduan/' + data.no_tiket;
      this.shareWeb(url);
    }
  }

  //Laporkan Pengaduan
  async confirmReportAlert() {
    let data = this.pengaduan;
    let current_user = this.current_user;

    const modal = await this.modalCtrl.create({
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      component: ModalAlertComponent,
      cssClass: 'sheet-modal',
      componentProps: {
        title: 'Dengan ini anda meyakini bahwa pengaduan ini mengandung konten yang sensitif dan tidak layak dipublikasi.',
        btnTitleConfirm: 'Laporkan Pengaduan',
        imageAlert: '../../../../assets/images/ilustration/undraw_report.svg',
      },
    });

    modal.onDidDismiss().then((res) => {
      let status = res['data']['status'];
      if (status) {
        this.reportPengaduan(data, current_user);
      }
      
    });

    return await modal.present();
  }

  reportPengaduan(pengaduan, current_user) {
    let data = {
      pengaduan_id: pengaduan.id,
      user_id: current_user,
    };

    this.loader.present();

    this.req.requestPost('report/addpengaduan', data).subscribe((res) => {
      this.loader.dismiss();
      this.modalCtrl.dismiss();
      if (res['success']) {
        this.helper.modalMessage(res['message'],'../../../../assets/images/ilustration/undraw_confirmed.svg');
      } else {
        this.helper.modalMessage(res['message'],'../../../../assets/images/ilustration/undraw_message.svg');
      }
    });
  }

  //Blok
  async confirmBlockAlert() {
    let data = this.pengaduan;
    let current_user = this.current_user;

    const modal = await this.modalCtrl.create({
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      component: ModalAlertComponent,
      cssClass: 'sheet-modal',
      componentProps: {
        title: 'Dengan memblokir ' +
        data.pelapor +
        ', maka pengaduan oleh ' +
        data.pelapor +
        ' tidak akan muncul pada halaman beranda anda.',
        btnTitleConfirm: 'Block Pelapor',
        imageAlert: '../../../../assets/images/ilustration/undraw_warning.svg',
      },
    });

    modal.onDidDismiss().then((res) => {
      let status = res['data']['status'];
      if (status) {
        this.blockUser(data.user_id, current_user);
      }
      
    });

    return await modal.present();

  }

  blockUser(blocked_user_id, current_user_id) {
    let data = {
      block_user_id: blocked_user_id,
      user_id: current_user_id,
    };

    this.loader.present();

    this.req.requestPost('blocks/add', data).subscribe((res) => {
      this.loader.dismiss();
      this.modalCtrl.dismiss();
      if (res['success']) {
        this.helper.modalMessage(res['message'],'../../../../assets/images/ilustration/undraw_confirmed.svg');
        this.navCtrl.navigateRoot('tabs/home');
      } else {
        this.helper.modalMessage(res['message'],'../../../../assets/images/ilustration/undraw_message.svg');
      }
    });
  }

  async shareWeb(url) {
    const modal = await this.modalCtrl.create({
      initialBreakpoint: 0.5,
      breakpoints: [0, 0.5],
      component: ModalShareWebComponent,
      componentProps: {
        url: url,
      },
    });

    return await modal.present();
  }

  //Delete Pengaduan
  async confirmDelete() {

    const modal = await this.modalCtrl.create({
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      component: ModalAlertComponent,
      cssClass: 'sheet-modal',
      componentProps: {
        title: 'Apakah anda yakin untuk menghapus pengaduan ini?',
        btnTitleConfirm: 'Hapus Pengaduan',
        imageAlert: '../../../../assets/images/ilustration/undraw_delete.svg',
      },
    });

    modal.onDidDismiss().then((res) => {
      let status = res['data']['status'];
      if (status) {
        this.deletePengaduan(this.pengaduan.id);
      }
      
    });

    return await modal.present();

  }

  deletePengaduan(pengaduan_id) {
    let data = {
      id: pengaduan_id,
    };

    this.loader.present();

    this.req
      .requestPost('pengaduan/remove-pengaduan', data)
      .subscribe((res) => {

        this.loader.dismiss();
        this.modalCtrl.dismiss();
        if (res['success']) {
          this.navCtrl.navigateRoot('riwayat-pengaduan');
          this.helper.modalMessage('Pengaduan Berhasil Dihapus','../../../../assets/images/ilustration/undraw_confirmed.svg');
        } else {
          this.helper.modalMessage(res['message'],'../../../../assets/images/ilustration/undraw_message.svg');
        }
      });
  }

  async confirmEdit() {

    const modal = await this.modalCtrl.create({
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      component: ModalAlertComponent,
      cssClass: 'sheet-modal',
      componentProps: {
        title: 'Apakah anda yakin untuk mengubah pengaduan ini?',
        btnTitleConfirm: 'Ubah',
        imageAlert: '../../../../assets/images/ilustration/undraw_questions.svg',
      },
    });

    modal.onDidDismiss().then((res) => {
      let status = res['data']['status'];
      if (status) {
        this.editPengaduan();
      }
      
    });

    return await modal.present();

  }

  //Edit Pengaduan
  async editPengaduan() {
    await this.navCtrl
      .navigateForward(['lapor-pengaduan/edit/', this.pengaduan.id])
      .then(() => {
        this.modalCtrl.dismiss();
      });
  }
}
