import { Component, Input, NgZone, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as L from 'leaflet';
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch';
import { Geolocation } from '@capacitor/geolocation';
import { HttpClient } from '@angular/common/http';
import { PermissionService } from 'src/app/_services/permission.service';
import { LeafletMouseEvent } from 'leaflet';

declare var google;

@Component({
  selector: 'app-modal-places',
  templateUrl: './modal-places.component.html',
  styleUrls: ['./modal-places.component.scss'],
})
export class ModalPlacesComponent implements OnInit {
  @Input() input_lat;
  @Input() input_lng;
  map: any;
  lat: any;
  lng: any;
  address_name: any = '';
  provider: any = new OpenStreetMapProvider();
  result: any;
  markers = [];
  search_text: any = '';
  show_spinner: boolean = true;
  tempData: any = [];
  customMarkerIcon = L.icon({
    iconUrl: 'assets/icon/svg/ico-solid-lokasi.svg',
    iconSize: [32, 32],
  });
  markeropts = {
    icon: this.customMarkerIcon,
    draggable: true,
    autoPan: true,
  };
  selected_lat: any;
  selected_lng: any;

  constructor(public modalCtrl: ModalController, private http: HttpClient, private permissionService: PermissionService) {
    this.result = [];
    this.markers = [];
    this.map = null;
  }

  ngOnInit() {}

  ionViewWillEnter() {
    this.show_spinner = true;
  }

  ionViewDidEnter() {
    this.initLeafletMap();
    this.tryGeoloc();
  }

  async initLeafletMap() {

    this.map = new L.Map('map').setView([-8.6027717, 115.1764153], 34);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);

    this.map.on("click", (e) => {
      let marker = L.marker([e.latlng.lat, e.latlng.lng], this.markeropts);
      this.markers.push(marker);
      this.addMarkers(e.latlng.lat, e.latlng.lng);

      this.lat = e.latlng.lat;
      this.lng = e.latlng.lng;
      localStorage.setItem('selected_lat', this.lat);
      localStorage.setItem('selected_lng', this.lng);

    });

    if (localStorage.getItem('selected_lat') && localStorage.getItem('selected_lng')) {
      this.addMarkers(localStorage.getItem('selected_lat'), localStorage.getItem('selected_lng'));
    }

  }

  async tryGeoloc() {

    if (this.permissionService.currentPermissionStatus == 1) {
      let coordinates = await Geolocation.getCurrentPosition();
      this.lat = coordinates.coords.latitude;
      this.lng = coordinates.coords.longitude;
    }else{
      this.lat = localStorage.getItem('selected_lat');
      this.lng = localStorage.getItem('selected_lng');
    }
  
    this.selected_lat = localStorage.getItem('selected_lat');
    this.selected_lng = localStorage.getItem('selected_lng');
    if (this.selected_lat && this.selected_lng) {
      let marker = L.marker(
        [this.selected_lat, this.selected_lng],
        this.markeropts
      );
      this.markers.push(marker);
      // this.clearMarkers();
      this.addMarkers(this.selected_lat, this.selected_lng);
      this.lat = this.selected_lat;
      this.lng = this.selected_lng;
    } else if (this.input_lat && this.input_lng) {
      let marker = L.marker([this.input_lat, this.input_lng], this.markeropts);
      this.markers.push(marker);
      // this.clearMarkers();
      this.addMarkers(this.input_lat, this.input_lng);
      this.lat = this.input_lat;
      this.lng = this.input_lng;
    }
    // } else {
    //   let marker = L.marker([this.lat, this.lng], this.markeropts);
    //   this.markers.push(marker);
    //   // this.clearMarkers();
    //   this.addMarkers(this.lat, this.lng);
    // }
  }

  clearMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.map.removeLayer(this.markers[i]);
    }

    this.markers = [];
  }

  addMarkers(lat, lng) {
    this.clearMarkers();
    let marker = L.marker([lat, lng], this.markeropts);
    marker.addTo(this.map);
    marker
      .bindPopup('<b> Klik dan tarik untuk mengubah lokasi </b>')
      .openPopup();
    this.map.panTo([lat, lng]);
    this.markers.push(marker);
    marker.on('dragend', () => {
      this.lat = marker.getLatLng().lat;
      this.lng = marker.getLatLng().lng;
      // this.geocoder(this.lat, this.lng);
      localStorage.setItem('selected_lat', this.lat);
      localStorage.setItem('selected_lng', this.lng);
    });
  }

  async updateSearchResults(ev) {
    let value = ev.target.value;

    if (value.length == '') {
      this.result = [];
      return;
    }

    this.provider.search({ query: value }).then((result) => {
      this.result = [];
      if (result) {
        result.forEach((item) => {
          this.result.push(item);
        });
      }
    });
  }

  async closeModal() {
    let data = {
      lat: this.lat,
      lng: this.lng,
    };

    await this.modalCtrl.dismiss(data);
  }

  selectSearchResult(item) {
    this.clearMarkers();
    this.search_text = '';
    this.result = [];

    if (item) {
      this.search_text = item.label;
      this.lat = item.y;
      this.lng = item.x;
      let marker = L.marker([this.lat, this.lng], this.markeropts);
      this.markers.push(marker);
      this.addMarkers(this.lat, this.lng);
      this.map.panTo([this.lat, this.lng]);
    }
  }
}
