import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-avatar',
  templateUrl: './modal-avatar.component.html',
  styleUrls: ['./modal-avatar.component.scss'],
})
export class ModalAvatarComponent implements OnInit {
  @Input() title: any;
  @Input() btnTitleConfirm: any;
  @Input() imageAlert: any;
  formProfile: FormGroup;
  user: any = [];
  selectedFile!: File;
  imageUrl: any;
  fileName: any;

  status: boolean = false;
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    this.setForm();
  }

  async submit() {
    // let data = {
    //   status: true,
    // };
    this.status = true;

    let data = {
      status: true,
      avatar: this.imageUrl,
    };
    await this.modalCtrl.dismiss(data);
  }

  async setForm() {
    this.formProfile = new FormGroup({
      avatar: new FormControl(null),
    });
  }

  async close() {
    this.status = false;
    let data = {
      status: this.status,
    };
    await this.modalCtrl.dismiss(data);
  }

  onFileSelected(event: any) {
    this.selectedFile = <File>event.target.files[0];

    if (event.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        this.fileName = '"' + event.target.files[0].name + '"';
        this.imageUrl = e.target.result;
      };
    }
  }

  onCancel() {
    this.fileName = null;
    this.imageUrl = null;
  }
}
