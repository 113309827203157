import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ComponentsModule } from './_components/components.module';
import { HeaderInterceptor } from './_interceptors/header.interceptor';
import { ErrorInterceptor } from './_interceptors/error.interceptor';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DatePipe, LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id';

registerLocaleData(localeId, 'id');
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    DropzoneModule,
  ],
  providers: [
    DatePipe,
    NativeGeocoder,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'id-ID' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
