import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HelpersService } from 'src/app/_services/helpers.service';
import { RequestService } from 'src/app/_services/request.service';

@Component({
  selector: 'app-modal-call',
  templateUrl: './modal-call.component.html',
  styleUrls: ['./modal-call.component.scss'],
})
export class ModalCallComponent implements OnInit {
  @Input() menu_id;
  dataKategori: any = [];
  isLoading: boolean = true;
  constructor(
    private req: RequestService,
    private modalCtrl: ModalController,
    public helper: HelpersService
  ) {}

  ngOnInit() {}

  ionViewDidEnter() {
    this.getMenuDetail(this.menu_id);
  }

  async getMenuDetail(id) {
    this.isLoading = true;
    await this.req
      .requestGet('pengaduan/menu/detail/' + id, '')
      .subscribe((res) => {
        this.dataKategori = res;
        this.isLoading = false;
      });
  }

  goCall(event) {
    let event_detail = event.split(',');
    if (event_detail[0] == 'call_number') {
      window.open('tel:' + event_detail[1]);
    } else {
      this.helper.presentToast(
        'No event',
        'bottom',
        'danger'
      );
    }
  }
  async close() {
    await this.modalCtrl.dismiss();
  }
}
